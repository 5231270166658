import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { Text } from "./core/commonExports"
import useHindiLangFont from "../hooks/useHindiLangFont"

const ThreatEvents = () => {
    const {t} = useTranslation('threat-events')
    const { hindiBold } = useHindiLangFont()
    return (
        <ThreatsContainer>
            <ThreatItemContainer>
              <ThreatIconContainer>
                <StaticImage 
                    src="../assets/images/threat-icons-mlp/fire.webp"
                    alt="fire-and-flood"
                    width={98}
                    height={98}
                />
              </ThreatIconContainer>
              <Text fontSize="18px" mfontSize="14px" lineHeight="22.68px" mlineHeight="17.64px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{marginTop: "2rem"}} mobileStyles={{marginTop: "1rem"}} className={hindiBold}>{t("FireBlood")}</Text>
            </ThreatItemContainer>
            <ThreatItemContainer>
              <ThreatIconContainer>
                  <StaticImage 
                      src="../assets/images/threat-icons-mlp/burglary-theft.webp"
                      alt="burglary-and-theft"
                      width={98}
                      height={98}
                  />
              </ThreatIconContainer>
              <Text fontSize="18px" mfontSize="14px" lineHeight="22.68px" mlineHeight="17.64px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{marginTop: "2rem"}} mobileStyles={{marginTop: "1rem"}} className={hindiBold}>{t("BurglaryTheft")}</Text>
            </ThreatItemContainer>
            <ThreatItemContainer>
              <ThreatIconContainer>
                <StaticImage 
                    src="../assets/images/threat-icons-mlp/riots.webp"
                    alt="riots-and-strikes"
                    width={98}
                    height={98}
                />
              </ThreatIconContainer>
              <Text fontSize="18px" mfontSize="14px" lineHeight="22.68px" mlineHeight="17.64px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{marginTop: "2rem"}} mobileStyles={{marginTop: "1rem"}} className={hindiBold}>{t("RiotsStrikes")}</Text>
            </ThreatItemContainer>
            <ThreatItemContainer>
              <ThreatIconContainer>
                <StaticImage 
                    src="../assets/images/threat-icons-mlp/explosions.webp"
                    alt="explosions-and-earthquakes"
                    width={98}
                    height={98}
                />
              </ThreatIconContainer>
              <Text fontSize="18px" mfontSize="14px" lineHeight="22.68px" mlineHeight="17.64px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{marginTop: "2rem"}} mobileStyles={{marginTop: "1rem"}} className={hindiBold}>{t("ExplosionsEarthquakes")}</Text>
            </ThreatItemContainer>
            <ThreatItemContainer>
              <ThreatIconContainer>
                <StaticImage 
                    src="../assets/images/threat-icons-mlp/lightning.webp"
                    alt="and-much-more"
                    width={98}
                    height={98}
                />
              </ThreatIconContainer>
              <Text fontSize="18px" mfontSize="14px" lineHeight="22.68px" mlineHeight="17.64px" fontWeight="bold" style={{textAlign: "center"}} desktopStyles={{marginTop: "2rem"}} mobileStyles={{marginTop: "1rem"}} className={hindiBold}>{t("MuchMore")}</Text>
            </ThreatItemContainer>
          </ThreatsContainer>
    )
}

export default ThreatEvents

const ThreatsContainer = styled.div`
  width: 100%; 
  display: flex; 
  justify-content: space-between; 
  align-items: start; 
  gap: 3.5rem;
  margin-bottom: 40px;
  margin-top: 80px;
  @media (max-width: 1250px) {
      gap: 1.5rem;
  }
  @media (max-width: 1080px) {
      gap: 1rem;
  }
  @media (max-width: 768px) {
      & > * {
          width: 150px;
      }
      justify-content: center;
      flex-wrap: wrap !important;
      margin-top: 40px;
  }
`

const ThreatItemContainer = styled.div`
  margin: 1rem auto;
  max-width: 175px;
`

const ThreatIconContainer = styled.div`
  width: 98.35px;
  margin: auto;
  @media screen and (max-width: 768px) {
      width: min(72px, 100%);
  }
`